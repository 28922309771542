<template>
  <div class="search">

    <div class="container mt-5">
      <div class="row" v-if="$isMobile()">
        <div class="col">
          <form class="d-flex" role="search" @submit.prevent="searchSubmit">

            <label for="search"><i class="bi bi-search mr-2"></i></label>
            <input
                ref="searchInput"
                v-model="query"
                class="form-control form-control-sm mb-3"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                @keyup.enter="searchSubmit"
                @focusout="searchSubmit"
            >
          </form>
        </div>
      </div>

      <app-section :title="$t('Búsqueda: ')+ $parent.searchQuery"></app-section>

      <div class="row search-results my-5">
        <template v-if="hasResults()">
          <div class="col-12 mb-5 mb-sm-4" v-for="(m,i) in movies" v-bind:key="i">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4">
                <router-link class="a-no-decoration" :to="{name: 'movie', params: {slug: m.slug}}">
                  <!-- <img :src="(m.search_thumbnail ? m.search_thumbnail : 'https://via.placeholder.com/450x253/29c1dd/ffffff?text='+m.title)" class="poster" /> -->
                  <!-- <img :src="(m.horizontal_poster ? m.horizontal_poster : 'https://via.placeholder.com/450x253/29c1dd/ffffff?text='+m.title)" class="poster" /> -->
                  <img :src="(m.search_thumbnail ? m.search_thumbnail : m.horizontal_poster)" class="poster" />
                </router-link>
              </div>
              <div class="col-12 col-sm-6 col-md-8">
                <router-link class="a-no-decoration" :to="{name: 'movie', params: {slug: m.slug}}">
                 <h4 class="text-white pt-3">{{  m.title }}</h4>

                <p class="my-3 text-muted" v-html="m.short_description"></p>
                  <span class="text-white">{{$t('Ver Más')}}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="awards.length > 0">
            <app-section :title="$t('Premios')"></app-section>
          </div>
          <div class="col-12 mb-5 mb-sm-4" v-for="(award,i) in awards" v-bind:key="i">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4">
                <router-link class="a-no-decoration" :to="{name: 'award', params: {slug: award.slug}}">
                  <img :src="(award.poster ? award.poster : 'https://via.placeholder.com/450x253/29c1dd/ffffff?text='+award.title)" class="poster" />
                </router-link>
              </div>
              <div class="col-12 col-sm-6 col-md-8">
                <router-link class="a-no-decoration" :to="{name: 'award', params: {slug: award.slug}}">
                  <h4 class="text-white pt-3">{{  award.title }}</h4>

                  <p class="my-3 text-muted" v-html="award.short_description"></p>
                  <span class="text-white">{{$t('Ver Más')}}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="news.length > 0">
            <app-section :title="$t('Noticias')"></app-section>
          </div>
          <div class="col-12 mb-5 mb-sm-4" v-for="(nw,i) in news" v-bind:key="i">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4">
                <router-link class="a-no-decoration" :to="{name: 'news', params: {slug: nw.slug}}">
                  <img :src="(nw.main_image ? nw.main_image : 'https://via.placeholder.com/450x253/29c1dd/ffffff?text='+nw.title)" class="poster" />
                </router-link>
              </div>
              <div class="col-12 col-sm-6 col-md-8">
                <router-link class="a-no-decoration" :to="{name: 'news', params: {slug: nw.slug}}">
                  <h4 class="text-white pt-3">{{  nw.title }}</h4>

                  <p class="my-3 text-muted" v-html="nw.short_description"></p>
                  <span class="text-white">{{$t('Ver Más')}}</span>
                </router-link>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-12 p-5">
            <p>{{$t('No hay resultados para la busqueda')}}</p>
          </div>
        </template>

      </div>
    </div>


  </div>
</template>

<script>
import AppSection from "@/components/AppSection";
export default {
  name: "Search",
  components: {
    AppSection
  },
  props:{
    query: {
      default(){
        return this.$parent.searchQuery
      },
      type:String
    }
  },
  data(){
    return {
      movies: [],
      news:   [],
      awards: []
    }
  },
  mounted() {
    this.refreshSearch()
  },
  methods:{
    description(m){
      let str = "";
      if(m.genres[0]){
        str += m.genres[0].name+" - "
      }
      if(m.year){
        str += m.year+" - "
      }
      if(m.duration){
        str += m.duration+" MIN"
      }
      str += "\n"
      if(m.cast){
        str += this.$t("REPARTO: ")+m.cast
      }


      return str
    },
    hasResults(){
      return this.movies.length > 0 || this.news.length > 0 || this.awards.length > 0
    },
    searchSubmit(){
      this.$parent.searchQuery = this.query;
    },
    refreshSearch(){
      this.$store.dispatch("fetchSearch",this.query).then((r)=>{
        this.movies = r.movies.data;
        this.news   = r.news.data;
        this.awards = r.awards.data;
      })
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
.search-results{
  .poster{
    width: 100%;
  }
}
.search{
  background-color: $medium-blue-2;
  min-height: 40vh;
}
</style>